import React from "react"

import DatasetDisplay from "../../components/DatasetDisplay"

// content specific imports
import dataUSMarketing from "../../data/data-us-marketing"
import ogImage from "../../images/data-points/175-large-us-marketing-teams.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["Account Name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor(row => row["nr. Channels"], {
    id: "nrChannels",
    cell: info => (
      <span className="text-xs border rounded px-1 shadow">
        {info.getValue()}
      </span>
    ),
    header: "# of Tactics",
  }),
  columnHelper.accessor("ABM Total", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-emerald-800 bg-emerald-50 rounded px-1">
          Account Based Marketing
        </span>
      ) : (
        <></>
      ),
    header: "ABM team?",
  }),
  columnHelper.accessor("Brand", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-pink-800 bg-pink-50 rounded px-1">
          Brand Marketing
        </span>
      ) : (
        <></>
      ),
    header: "Brand Marketing team?",
  }),
  columnHelper.accessor("Content", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-yellow-800 bg-yellow-50 rounded px-1">
          Content Marketing
        </span>
      ) : (
        <></>
      ),
    header: "Content Marketing team?",
  }),
  columnHelper.accessor("Demand Generation", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-lime-800 bg-lime-50 rounded px-1">
          Demand Gen
        </span>
      ) : (
        <></>
      ),
    header: "Demand generation team?",
  }),
  columnHelper.accessor("Events", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-violet-800 bg-violet-50 rounded px-1">
          Events
        </span>
      ) : (
        <></>
      ),
    header: "Events team?",
  }),
  columnHelper.accessor("SEO", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-sky-800 bg-sky-50 rounded px-1">SEO</span>
      ) : (
        <></>
      ),
    header: "SEO team?",
  }),
]

const dataContext = {
  title: "How are large US marketing teams organised?",
  description:
    "Marketers love to innovate, and every year there are new ways to make marketing teams perform better. We looked at 5,000 large US companies to find out how they are doing.",
  blurb:
    "Marketers love to innovate, and every year there are new ways to make marketing teams perform better. Teams focus on Account Based Marketing, SEO, Brand and more. We looked at 5,000 large US companies to find out how they are doing.",
  ctaText:
    "Get the whole list of 175 companies and their marketing teams, and sync it with your CRM.",
  slug: "us-marketing-channels-analysis",
  freeLimit: 50,
  blogPost: (
    <>
      <p>
        Here's the first 50 companies in this dataset. If you'd like to ask
        additional questions (e.g. who has monthly pricing?), integrate this
        into your CRM, or access the rest of the dataset, please{" "}
        <a href="#cta">contact us for a chat</a>!
      </p>
    </>
  ),
  columns: columns,
  published: "Jan 12, 2023",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={dataUSMarketing}
      dataContext={dataContext}
    />
  )
}

export default DataSet
