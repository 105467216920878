const DataUSMarketing = [
  {
    "Account Name": "Adobe",
    Website: "http://www.adobe.com",
    "# Employees": 36000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 207,
    ABM: 11,
    "Account Based": 2,
    "ABM Total": 13,
    Brand: 36,
    Content: 61,
    "Demand Generation": 26,
    Events: 32,
    SEO: 26,
    "nr. Channels": 6,
  },
  {
    "Account Name": "HP",
    Website: "http://www.hp.com",
    "# Employees": 182000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 148,
    ABM: 8,
    "Account Based": 5,
    "ABM Total": 13,
    Brand: 41,
    Content: 37,
    "Demand Generation": 12,
    Events: 12,
    SEO: 20,
    "nr. Channels": 6,
  },
  {
    "Account Name": "Microsoft",
    Website: "http://www.microsoft.com",
    "# Employees": 234000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 221,
    ABM: 7,
    "Account Based": 8,
    "ABM Total": 15,
    Brand: 42,
    Content: 70,
    "Demand Generation": "",
    Events: 41,
    SEO: 38,
    "nr. Channels": 5,
  },
  {
    "Account Name": "Chase",
    Website: "http://www.chase.com",
    "# Employees": 250000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 206,
    ABM: 45,
    "Account Based": "",
    "ABM Total": 45,
    Brand: 29,
    Content: 41,
    "Demand Generation": 2,
    Events: 33,
    SEO: 11,
    "nr. Channels": 5,
  },
  {
    "Account Name": "Dell Technologies",
    Website: "http://www.dell.com",
    "# Employees": 141000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 113,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 26,
    Content: 43,
    "Demand Generation": 11,
    Events: 20,
    SEO: 13,
    "nr. Channels": 5,
  },
  {
    "Account Name": "Google",
    Website: "http://www.google.com",
    "# Employees": 317000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 222,
    ABM: 3,
    "Account Based": 3,
    "ABM Total": 6,
    Brand: 71,
    Content: 75,
    "Demand Generation": 17,
    Events: 47,
    SEO: "",
    "nr. Channels": 4,
  },
  {
    "Account Name": "Meta",
    Website: "http://www.facebook.com",
    "# Employees": 124000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 210,
    ABM: "",
    "Account Based": 1,
    "ABM Total": 1,
    Brand: 65,
    Content: 95,
    "Demand Generation": 4,
    Events: 31,
    SEO: 13,
    "nr. Channels": 4,
  },
  {
    "Account Name": "EXPRESS",
    Website: "http://www.express.com",
    "# Employees": 9200,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 212,
    ABM: 6,
    "Account Based": "",
    "ABM Total": 6,
    Brand: 55,
    Content: 65,
    "Demand Generation": 8,
    Events: 35,
    SEO: 37,
    "nr. Channels": 4,
  },
  {
    "Account Name": "JPMorgan Chase",
    Website: "http://www.jpmorganchase.com",
    "# Employees": 290000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 219,
    ABM: 22,
    "Account Based": "",
    "ABM Total": 22,
    Brand: 49,
    Content: 72,
    "Demand Generation": 2,
    Events: 46,
    SEO: 6,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Autodesk",
    Website: "http://www.autodesk.com",
    "# Employees": 15000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 251,
    ABM: 24,
    "Account Based": 36,
    "ABM Total": 60,
    Brand: 36,
    Content: 62,
    "Demand Generation": 24,
    Events: 9,
    SEO: "",
    "nr. Channels": 4,
  },
  {
    "Account Name": "Apple",
    Website: "http://www.apple.com",
    "# Employees": 263000,
    Industry: "electrical/electronic manufacturing",
    "Company Country": "United States",
    "# People Results": 193,
    ABM: 5,
    "Account Based": "",
    "ABM Total": 5,
    Brand: 9,
    Content: 80,
    "Demand Generation": 12,
    Events: 35,
    SEO: 47,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Amazon",
    Website: "http://www.amazon.com",
    "# Employees": 1468000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 192,
    ABM: 10,
    "Account Based": 1,
    "ABM Total": 11,
    Brand: 88,
    Content: 5,
    "Demand Generation": 2,
    Events: 54,
    SEO: 21,
    "nr. Channels": 4,
  },
  {
    "Account Name": "IBM",
    Website: "http://www.ibm.com",
    "# Employees": 424000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 198,
    ABM: 17,
    "Account Based": 15,
    "ABM Total": 32,
    Brand: 66,
    Content: 4,
    "Demand Generation": 9,
    Events: 34,
    SEO: 21,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Intuit",
    Website: "http://www.intuit.com",
    "# Employees": 16000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 156,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 57,
    Content: 70,
    "Demand Generation": 1,
    Events: 13,
    SEO: 15,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Walmart",
    Website: "http://www.walmart.com",
    "# Employees": 2300000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 151,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 27,
    Content: 80,
    "Demand Generation": 1,
    Events: 23,
    SEO: 20,
    "nr. Channels": 4,
  },
  {
    "Account Name": "ServiceNow",
    Website: "http://www.servicenow.com",
    "# Employees": 22000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 159,
    ABM: 4,
    "Account Based": 11,
    "ABM Total": 15,
    Brand: 35,
    Content: 69,
    "Demand Generation": 2,
    Events: 23,
    SEO: "",
    "nr. Channels": 4,
  },
  {
    "Account Name": "3M",
    Website: "http://www.3m.com",
    "# Employees": 71000,
    Industry: "machinery",
    "Company Country": "United States",
    "# People Results": 119,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 46,
    Content: 38,
    "Demand Generation": 8,
    Events: 16,
    SEO: 11,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Oracle",
    Website: "http://www.oracle.com",
    "# Employees": 222000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 122,
    ABM: 2,
    "Account Based": 3,
    "ABM Total": 5,
    Brand: 33,
    Content: 24,
    "Demand Generation": 17,
    Events: 31,
    SEO: 7,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Gartner",
    Website: "http://www.gartner.com",
    "# Employees": 22000,
    Industry: "information services",
    "Company Country": "United States",
    "# People Results": 118,
    ABM: "",
    "Account Based": 1,
    "ABM Total": 1,
    Brand: 12,
    Content: 69,
    "Demand Generation": 1,
    Events: 17,
    SEO: 17,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Johnson & Johnson",
    Website: "http://www.jnj.com",
    "# Employees": 145000,
    Industry: "hospital & health care",
    "Company Country": "United States",
    "# People Results": 129,
    ABM: 16,
    "Account Based": "",
    "ABM Total": 16,
    Brand: 48,
    Content: 11,
    "Demand Generation": 7,
    Events: 25,
    SEO: 6,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Conde Nast",
    Website: "http://www.condenast.com",
    "# Employees": 7300,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 108,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 26,
    Content: 40,
    "Demand Generation": "",
    Events: 25,
    SEO: 17,
    "nr. Channels": 4,
  },
  {
    "Account Name": "CVS",
    Website: "http://www.cvs.com",
    "# Employees": 5700,
    Industry: "pharmaceuticals",
    "Company Country": "United States",
    "# People Results": 108,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 27,
    Content: 52,
    "Demand Generation": "",
    Events: 18,
    SEO: 11,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Pharma",
    Website: "http://www.pharmamt.com",
    "# Employees": 7500,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 95,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 51,
    Content: 11,
    "Demand Generation": 2,
    Events: 13,
    SEO: 18,
    "nr. Channels": 4,
  },
  {
    "Account Name": "HubSpot",
    Website: "http://www.hubspot.com",
    "# Employees": 8900,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 93,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 25,
    Content: 35,
    "Demand Generation": 3,
    Events: 13,
    SEO: 17,
    "nr. Channels": 4,
  },
  {
    "Account Name": "FITNESS",
    Website: "http://www.fitnessblender.com",
    "# Employees": 2200,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 91,
    ABM: "",
    "Account Based": 1,
    "ABM Total": 1,
    Brand: 28,
    Content: 22,
    "Demand Generation": "",
    Events: 26,
    SEO: 13,
    "nr. Channels": 4,
  },
  {
    "Account Name": "CVS Health",
    Website: "http://www.cvshealth.com",
    "# Employees": 295000,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 89,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 13,
    Content: 50,
    "Demand Generation": "",
    Events: 15,
    SEO: 11,
    "nr. Channels": 4,
  },
  {
    "Account Name": "ComputER",
    Website: "http://www.compute-er.com",
    "# Employees": 3000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 92,
    ABM: 3,
    "Account Based": "",
    "ABM Total": 3,
    Brand: 11,
    Content: 36,
    "Demand Generation": "",
    Events: 20,
    SEO: 19,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Yellow",
    Website: "http://www.myyellow.com",
    "# Employees": 19000,
    Industry: "transportation/trucking/railroad",
    "Company Country": "United States",
    "# People Results": 89,
    ABM: 1,
    "Account Based": 1,
    "ABM Total": 2,
    Brand: 23,
    Content: 26,
    "Demand Generation": 4,
    Events: 13,
    SEO: 19,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Vista",
    Website: "http://www.vista.com",
    "# Employees": 5000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 81,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 22,
    Content: 18,
    "Demand Generation": "",
    Events: 24,
    SEO: 17,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Atlas",
    Website: "http://www.oneatlas.com",
    "# Employees": 2100,
    Industry: "civil engineering",
    "Company Country": "United States",
    "# People Results": 79,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 22,
    Content: 21,
    "Demand Generation": 1,
    Events: 20,
    SEO: 13,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Spectrum",
    Website: "http://www.spectrum.com",
    "# Employees": 79000,
    Industry: "telecommunications",
    "Company Country": "United States",
    "# People Results": 76,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 14,
    Content: 28,
    "Demand Generation": 1,
    Events: 14,
    SEO: 17,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Oasis",
    Website: "http://www.oasisinet.com",
    "# Employees": 2200,
    Industry: "human resources",
    "Company Country": "United States",
    "# People Results": 71,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 15,
    Content: 13,
    "Demand Generation": "",
    Events: 14,
    SEO: 29,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Best Buy",
    Website: "http://www.bestbuy.com",
    "# Employees": 71000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 65,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 23,
    Content: 17,
    "Demand Generation": "",
    Events: 12,
    SEO: 13,
    "nr. Channels": 4,
  },
  {
    "Account Name": "Mosaic",
    Website: "http://www.mosaicinfo.org",
    "# Employees": 2200,
    Industry: "hospital & health care",
    "Company Country": "United States",
    "# People Results": 51,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 15,
    Content: 12,
    "Demand Generation": "",
    Events: 12,
    SEO: 12,
    "nr. Channels": 4,
  },
  {
    "Account Name": "AT&T",
    Website: "http://www.att.com",
    "# Employees": 267000,
    Industry: "telecommunications",
    "Company Country": "United States",
    "# People Results": 182,
    ABM: 3,
    "Account Based": "",
    "ABM Total": 3,
    Brand: 55,
    Content: 50,
    "Demand Generation": 5,
    Events: 59,
    SEO: 7,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Fiverr",
    Website: "http://www.fiverr.com",
    "# Employees": 149000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 167,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 35,
    Content: 80,
    "Demand Generation": "",
    Events: "",
    SEO: 52,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Salesforce",
    Website: "http://www.salesforce.com",
    "# Employees": 74000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 197,
    ABM: 14,
    "Account Based": 17,
    "ABM Total": 31,
    Brand: 59,
    Content: 65,
    "Demand Generation": 2,
    Events: 1,
    SEO: 8,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Nike",
    Website: "http://www.nike.com",
    "# Employees": 70000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 161,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 87,
    Content: 48,
    "Demand Generation": "",
    Events: 20,
    SEO: 6,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Procter & Gamble",
    Website: "http://www.pg.com",
    "# Employees": 95000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 171,
    ABM: 26,
    "Account Based": "",
    "ABM Total": 26,
    Brand: 94,
    Content: 15,
    "Demand Generation": "",
    Events: 8,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Comcast",
    Website: "http://www.comcast.com",
    "# Employees": 189000,
    Industry: "telecommunications",
    "Company Country": "United States",
    "# People Results": 145,
    ABM: "",
    "Account Based": 2,
    "ABM Total": 2,
    Brand: 33,
    Content: 68,
    "Demand Generation": "",
    Events: 35,
    SEO: 5,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Lowe's",
    Website: "http://www.lowes.com",
    "# Employees": 300000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 144,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 85,
    Content: 35,
    "Demand Generation": "",
    Events: 16,
    SEO: 6,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Wells Fargo",
    Website: "http://www.wellsfargo.com",
    "# Employees": 225000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 142,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 44,
    Content: 59,
    "Demand Generation": "",
    Events: 30,
    SEO: 9,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Vanguard",
    Website: "http://www.vanguard.com",
    "# Employees": 23000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 133,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 41,
    Content: 77,
    "Demand Generation": "",
    Events: 13,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Nordstrom",
    Website: "http://www.nordstrom.com",
    "# Employees": 62000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 131,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 82,
    Content: 30,
    "Demand Generation": "",
    Events: 14,
    SEO: 5,
    "nr. Channels": 3,
  },
  {
    "Account Name": "OWN",
    Website: "http://www.oneworldnetwork.com",
    "# Employees": 2900,
    Industry: "real estate",
    "Company Country": "United States",
    "# People Results": 131,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: "",
    Content: 58,
    "Demand Generation": "",
    Events: 31,
    SEO: 40,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Square",
    Website: "http://www.squareup.com",
    "# Employees": 7000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 128,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 49,
    Content: 4,
    "Demand Generation": 3,
    Events: 34,
    SEO: 36,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Compass",
    Website: "http://www.compass.com",
    "# Employees": 25000,
    Industry: "real estate",
    "Company Country": "United States",
    "# People Results": 128,
    ABM: 10,
    "Account Based": "",
    "ABM Total": 10,
    Brand: 34,
    Content: 28,
    "Demand Generation": 3,
    Events: 43,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Intel",
    Website: "http://www.intel.com",
    "# Employees": 136000,
    Industry: "semiconductors",
    "Company Country": "United States",
    "# People Results": 120,
    ABM: 1,
    "Account Based": 2,
    "ABM Total": 3,
    Brand: 43,
    Content: 38,
    "Demand Generation": "",
    Events: 24,
    SEO: 9,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Marriott International",
    Website: "http://www.marriott.com",
    "# Employees": 209000,
    Industry: "hospitality",
    "Company Country": "United States",
    "# People Results": 115,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 48,
    Content: 23,
    "Demand Generation": 3,
    Events: 35,
    SEO: 6,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Hilton Worldwide",
    Website: "http://www.hilton.com",
    "# Employees": 173000,
    Industry: "hospitality",
    "Company Country": "United States",
    "# People Results": 114,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 38,
    Content: 11,
    "Demand Generation": "",
    Events: 64,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Expedia Group",
    Website: "http://www.expediagroup.com",
    "# Employees": 22000,
    Industry: "online media",
    "Company Country": "United States",
    "# People Results": 113,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 39,
    Content: 50,
    "Demand Generation": "",
    Events: 5,
    SEO: 19,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Delta Air Lines",
    Website: "http://www.delta.com",
    "# Employees": 83000,
    Industry: "airlines/aviation",
    "Company Country": "United States",
    "# People Results": 112,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 67,
    Content: 20,
    "Demand Generation": "",
    Events: 23,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "MGM",
    Website: "http://www.mgm.com",
    "# Employees": 4300,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 109,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 28,
    Content: 31,
    "Demand Generation": "",
    Events: 50,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Pfizer",
    Website: "http://www.pfizer.com",
    "# Employees": 107000,
    Industry: "pharmaceuticals",
    "Company Country": "United States",
    "# People Results": 113,
    ABM: 6,
    "Account Based": "",
    "ABM Total": 6,
    Brand: 61,
    Content: 25,
    "Demand Generation": "",
    Events: 14,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Riot Games",
    Website: "http://www.riotgames.com",
    "# Employees": 7200,
    Industry: "computer games",
    "Company Country": "United States",
    "# People Results": 106,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 49,
    Content: 46,
    "Demand Generation": "",
    Events: 11,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Real",
    Website: "http://www.joinreal.com",
    "# Employees": 2800,
    Industry: "real estate",
    "Company Country": "United States",
    "# People Results": 104,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 38,
    Content: 3,
    "Demand Generation": 1,
    Events: 23,
    SEO: 37,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Red Hat",
    Website: "http://www.redhat.com",
    "# Employees": 21000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 120,
    ABM: 5,
    "Account Based": 13,
    "ABM Total": 18,
    Brand: 1,
    Content: 50,
    "Demand Generation": 1,
    Events: 27,
    SEO: 5,
    "nr. Channels": 3,
  },
  {
    "Account Name": "eBay",
    Website: "http://www.ebayinc.com",
    "# Employees": 18000,
    Industry: "online media",
    "Company Country": "United States",
    "# People Results": 100,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 31,
    Content: 45,
    "Demand Generation": "",
    Events: 6,
    SEO: 18,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Hyatt Hotels Corporation",
    Website: "http://www.hyatt.com",
    "# Employees": 130000,
    Industry: "hospitality",
    "Company Country": "United States",
    "# People Results": 100,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 20,
    Content: 24,
    "Demand Generation": "",
    Events: 53,
    SEO: 3,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Thermo Fisher Scientific",
    Website: "http://www.thermofisher.com",
    "# Employees": 69000,
    Industry: "research",
    "Company Country": "United States",
    "# People Results": 100,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 16,
    Content: 49,
    "Demand Generation": 1,
    Events: 30,
    SEO: 4,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Verizon",
    Website: "http://www.verizon.com",
    "# Employees": 118000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 101,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 28,
    Content: 6,
    "Demand Generation": 11,
    Events: 45,
    SEO: 9,
    "nr. Channels": 3,
  },
  {
    "Account Name": "CARE",
    Website: "http://www.care.org",
    "# Employees": 7000,
    Industry: "nonprofit organization management",
    "Company Country": "United States",
    "# People Results": 122,
    ABM: 22,
    "Account Based": "",
    "ABM Total": 22,
    Brand: 8,
    Content: 52,
    "Demand Generation": "",
    Events: "",
    SEO: 18,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Whirlpool Corporation",
    Website: "http://www.whirlpoolcorp.com",
    "# Employees": 78000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 99,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 53,
    Content: 26,
    "Demand Generation": 1,
    Events: 4,
    SEO: 15,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Vmware",
    Website: "http://www.vmware.com",
    "# Employees": 38000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 105,
    ABM: "",
    "Account Based": 6,
    "ABM Total": 6,
    Brand: 23,
    Content: 45,
    "Demand Generation": 1,
    Events: 17,
    SEO: 7,
    "nr. Channels": 3,
  },
  {
    "Account Name": "YMCA",
    Website: "http://www.ymca.net",
    "# Employees": 4500,
    Industry: "",
    "Company Country": "United States",
    "# People Results": 98,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 27,
    Content: 13,
    "Demand Generation": "",
    Events: 58,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "AbbVie",
    Website: "http://www.abbvie.com",
    "# Employees": 52000,
    Industry: "pharmaceuticals",
    "Company Country": "United States",
    "# People Results": 97,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 74,
    Content: 11,
    "Demand Generation": "",
    Events: 11,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "S&P Global",
    Website: "http://www.spglobal.com",
    "# Employees": 35000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 97,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 2,
    Content: 60,
    "Demand Generation": 12,
    Events: 23,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Publicis",
    Website: "http://www.publicisna.com",
    "# Employees": 76000,
    Industry: "marketing & advertising",
    "Company Country": "United States",
    "# People Results": 97,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 25,
    Content: 13,
    "Demand Generation": "",
    Events: 7,
    SEO: 52,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Monster",
    Website: "http://www.monster.com",
    "# Employees": 3800,
    Industry: "online media",
    "Company Country": "United States",
    "# People Results": 90,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 40,
    Content: 27,
    "Demand Generation": "",
    Events: 13,
    SEO: 10,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Macmillan",
    Website: "http://www.macmillan.com",
    "# Employees": 2300,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 89,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 14,
    Content: 29,
    "Demand Generation": "",
    Events: 44,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "National Basketball Association (NBA)",
    Website: "http://www.nba.com",
    "# Employees": 22000,
    Industry: "sports",
    "Company Country": "United States",
    "# People Results": 89,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 67,
    "Demand Generation": "",
    Events: 11,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Hearst",
    Website: "http://www.hearst.com",
    "# Employees": 20000,
    Industry: "media production",
    "Company Country": "United States",
    "# People Results": 87,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 16,
    Content: 33,
    "Demand Generation": "",
    Events: 10,
    SEO: 28,
    "nr. Channels": 3,
  },
  {
    "Account Name": "IEEE",
    Website: "http://www.ieee.org",
    "# Employees": 10000,
    Industry: "nonprofit organization management",
    "Company Country": "United States",
    "# People Results": 87,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 18,
    Content: 43,
    "Demand Generation": 1,
    Events: 25,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Nationwide",
    Website: "http://www.nationwide.com",
    "# Employees": 31000,
    Industry: "insurance",
    "Company Country": "United States",
    "# People Results": 85,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 24,
    Content: 40,
    "Demand Generation": "",
    Events: 16,
    SEO: 5,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Landmark",
    Website: "http://www.landmarkworldwide.com",
    "# Employees": 2300,
    Industry: "professional training & coaching",
    "Company Country": "United States",
    "# People Results": 85,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 39,
    Content: 22,
    "Demand Generation": "",
    Events: 24,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Bristol Myers Squibb",
    Website: "http://www.bms.com",
    "# Employees": 39000,
    Industry: "pharmaceuticals",
    "Company Country": "United States",
    "# People Results": 84,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 23,
    Content: 38,
    "Demand Generation": "",
    Events: 22,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Box",
    Website: "http://www.box.com",
    "# Employees": 2900,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 81,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 16,
    Content: 40,
    "Demand Generation": 1,
    Events: 10,
    SEO: 14,
    "nr. Channels": 3,
  },
  {
    "Account Name": "CBS",
    Website: "http://www.cbs.com",
    "# Employees": 6800,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 80,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 20,
    Content: 37,
    "Demand Generation": "",
    Events: 21,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Cisco Systems",
    Website: "http://www.cisco.com",
    "# Employees": 101000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 82,
    ABM: 1,
    "Account Based": 2,
    "ABM Total": 3,
    Brand: 16,
    Content: 35,
    "Demand Generation": 4,
    Events: 21,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Endeavor",
    Website: "http://www.endeavorco.com",
    "# Employees": 12000,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 76,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 34,
    Content: 18,
    "Demand Generation": "",
    Events: 20,
    SEO: 4,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Home Depot",
    Website: "http://www.homedepot.com",
    "# Employees": 491000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 74,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 45,
    "Demand Generation": 1,
    Events: "",
    SEO: 17,
    "nr. Channels": 3,
  },
  {
    "Account Name": "The Estee Lauder Companies Inc.",
    Website: "http://www.elcompanies.com",
    "# Employees": 48000,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 74,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 43,
    Content: 14,
    "Demand Generation": "",
    Events: 14,
    SEO: 3,
    "nr. Channels": 3,
  },
  {
    "Account Name": "AAA",
    Website: "http://www.aaa.com",
    "# Employees": 11000,
    Industry: "consumer services",
    "Company Country": "United States",
    "# People Results": 73,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 21,
    Content: 29,
    "Demand Generation": 2,
    Events: 10,
    SEO: 11,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Genesis",
    Website: "http://www.genesishcc.com",
    "# Employees": 15000,
    Industry: "hospital & health care",
    "Company Country": "United States",
    "# People Results": 74,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 24,
    Content: 15,
    "Demand Generation": 6,
    Events: 18,
    SEO: 9,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Schneider",
    Website: "http://www.schneider.com",
    "# Employees": 11000,
    Industry: "transportation/trucking/railroad",
    "Company Country": "United States",
    "# People Results": 73,
    ABM: "",
    "Account Based": 1,
    "ABM Total": 1,
    Brand: 22,
    Content: 28,
    "Demand Generation": 14,
    Events: "",
    SEO: 7,
    "nr. Channels": 3,
  },
  {
    "Account Name": "ACT",
    Website: "http://www.act.org",
    "# Employees": 3300,
    Industry: "government administration",
    "Company Country": "United States",
    "# People Results": 69,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 22,
    Content: 19,
    "Demand Generation": 1,
    Events: 27,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Morgan Stanley",
    Website: "http://www.morganstanley.com",
    "# Employees": 84000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 65,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 17,
    Content: 24,
    "Demand Generation": "",
    Events: 23,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "PATH",
    Website: "http://www.path.org",
    "# Employees": 2700,
    Industry: "international affairs",
    "Company Country": "United States",
    "# People Results": 66,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 19,
    Content: 20,
    "Demand Generation": "",
    Events: 6,
    SEO: 19,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Medtronic",
    Website: "http://www.medtronic.com",
    "# Employees": 95000,
    Industry: "medical devices",
    "Company Country": "United States",
    "# People Results": 64,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 23,
    Content: 20,
    "Demand Generation": 3,
    Events: 17,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "USA TODAY",
    Website: "http://www.usatoday.com",
    "# Employees": 2600,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 64,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 13,
    Content: 27,
    "Demand Generation": 1,
    Events: 14,
    SEO: 9,
    "nr. Channels": 3,
  },
  {
    "Account Name": "NICE",
    Website: "http://www.nice.com",
    "# Employees": 6800,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 64,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 21,
    Content: 17,
    "Demand Generation": 3,
    Events: 14,
    SEO: 7,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Block",
    Website: "http://www.block.xyz",
    "# Employees": 14000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 62,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 17,
    Content: 18,
    "Demand Generation": "",
    Events: 17,
    SEO: 10,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Visa",
    Website: "http://www.visa.com",
    "# Employees": 24000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 62,
    ABM: 2,
    "Account Based": "",
    "ABM Total": 2,
    Brand: 3,
    Content: 21,
    "Demand Generation": "",
    Events: 22,
    SEO: 12,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Goldman Sachs",
    Website: "http://www.goldmansachs.com",
    "# Employees": 62000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 59,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 21,
    Content: 24,
    "Demand Generation": "",
    Events: 13,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "The New York Times",
    Website: "http://www.nytco.com",
    "# Employees": 15000,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 59,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 30,
    Content: 7,
    "Demand Generation": "",
    Events: 11,
    SEO: 11,
    "nr. Channels": 3,
  },
  {
    "Account Name": "BNY Mellon",
    Website: "http://www.bnymellon.com",
    "# Employees": 50000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 58,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 16,
    Content: 13,
    "Demand Generation": "",
    Events: 29,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Snowflake",
    Website: "http://www.snowflake.com",
    "# Employees": 6000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 70,
    ABM: 3,
    "Account Based": 9,
    "ABM Total": 12,
    Brand: 6,
    Content: 12,
    "Demand Generation": 20,
    Events: 8,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "UNICEF",
    Website: "http://www.unicef.org",
    "# Employees": 36000,
    Industry: "nonprofit organization management",
    "Company Country": "United States",
    "# People Results": 58,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 17,
    Content: 21,
    "Demand Generation": 2,
    Events: 18,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "National Geographic",
    Website: "http://www.nationalgeographic.com",
    "# Employees": 7000,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 57,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 13,
    Content: 29,
    "Demand Generation": "",
    Events: 15,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Indigo",
    Website: "http://www.indigoag.com",
    "# Employees": 2200,
    Industry: "farming",
    "Company Country": "United States",
    "# People Results": 56,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 20,
    Content: 19,
    "Demand Generation": "",
    Events: 17,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Kyndryl",
    Website: "http://www.kyndryl.com",
    "# Employees": 90000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 66,
    ABM: 6,
    "Account Based": 5,
    "ABM Total": 11,
    Brand: 15,
    Content: 15,
    "Demand Generation": 6,
    Events: 8,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Cvent",
    Website: "http://www.cvent.com",
    "# Employees": 5500,
    Industry: "online media",
    "Company Country": "United States",
    "# People Results": 55,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: "",
    Content: 16,
    "Demand Generation": 27,
    Events: 11,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Fiserv",
    Website: "http://www.fiserv.com",
    "# Employees": 44000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 53,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 21,
    Content: 14,
    "Demand Generation": 5,
    Events: 13,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Amway",
    Website: "http://www.amwayglobal.com",
    "# Employees": 38000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 53,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 15,
    "Demand Generation": "",
    Events: 25,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Benchmark",
    Website: "http://www.bench.com",
    "# Employees": 20000,
    Industry: "electrical/electronic manufacturing",
    "Company Country": "United States",
    "# People Results": 51,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 17,
    Content: 13,
    "Demand Generation": "",
    Events: 18,
    SEO: 3,
    "nr. Channels": 3,
  },
  {
    "Account Name": "National Football League (NFL)",
    Website: "http://www.nfl.com",
    "# Employees": 4300,
    Industry: "sports",
    "Company Country": "United States",
    "# People Results": 50,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 27,
    "Demand Generation": "",
    Events: 12,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "McKinsey & Company",
    Website: "http://www.mckinsey.com",
    "# Employees": 45000,
    Industry: "management consulting",
    "Company Country": "United States",
    "# People Results": 50,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 13,
    Content: 11,
    "Demand Generation": "",
    Events: 25,
    SEO: 1,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Capital Group",
    Website: "http://www.capitalgroup.com",
    "# Employees": 8800,
    Industry: "investment management",
    "Company Country": "United States",
    "# People Results": 48,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 3,
    Content: 19,
    "Demand Generation": "",
    Events: 13,
    SEO: 13,
    "nr. Channels": 3,
  },
  {
    "Account Name": "REI",
    Website: "http://www.rei.com",
    "# Employees": 15000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 48,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 12,
    Content: 18,
    "Demand Generation": "",
    Events: 11,
    SEO: 7,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Agilent Technologies",
    Website: "http://www.agilent.com",
    "# Employees": 18000,
    Industry: "research",
    "Company Country": "United States",
    "# People Results": 47,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 22,
    "Demand Generation": "",
    Events: 1,
    SEO: 13,
    "nr. Channels": 3,
  },
  {
    "Account Name": "First Bank",
    Website: "http://www.first.bank",
    "# Employees": 2600,
    Industry: "banking",
    "Company Country": "United States",
    "# People Results": 58,
    ABM: 12,
    "Account Based": "",
    "ABM Total": 12,
    Brand: 11,
    Content: 4,
    "Demand Generation": 1,
    Events: 16,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Selbstst√§ndig",
    Website: "http://www.page-n.de",
    "# Employees": 14000,
    Industry: "professional training & coaching",
    "Company Country": "United States",
    "# People Results": 46,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 14,
    Content: 17,
    "Demand Generation": "",
    Events: 15,
    SEO: "",
    "nr. Channels": 3,
  },
  {
    "Account Name": "Toast",
    Website: "http://www.toasttab.com",
    "# Employees": 4400,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 43,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 13,
    Content: 15,
    "Demand Generation": 2,
    Events: 11,
    SEO: 2,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Vogue",
    Website: "http://www.vogue.com",
    "# Employees": 2600,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 39,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 12,
    "Demand Generation": "",
    Events: 12,
    SEO: 4,
    "nr. Channels": 3,
  },
  {
    "Account Name": "Essence",
    Website: "http://www.essenceglobal.com",
    "# Employees": 3000,
    Industry: "marketing & advertising",
    "Company Country": "United States",
    "# People Results": 35,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 13,
    "Demand Generation": "",
    Events: "",
    SEO: 11,
    "nr. Channels": 3,
  },
  {
    "Account Name": "TikTok",
    Website: "http://www.tiktok.com",
    "# Employees": 36000,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 180,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 88,
    Content: 90,
    "Demand Generation": "",
    Events: 1,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "YouTube",
    Website: "http://www.youtube.com",
    "# Employees": 135000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 139,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 40,
    Content: 94,
    "Demand Generation": "",
    Events: 5,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Upwork",
    Website: "http://www.upwork.com",
    "# Employees": 86000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 137,
    ABM: 4,
    "Account Based": "",
    "ABM Total": 4,
    Brand: 43,
    Content: 82,
    "Demand Generation": 2,
    Events: 2,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Fidelity Investments",
    Website: "http://www.fidelity.com",
    "# Employees": 70000,
    Industry: "financial services",
    "Company Country": "United States",
    "# People Results": 126,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 6,
    Content: 73,
    "Demand Generation": "",
    Events: 45,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Netflix",
    Website: "http://www.netflix.com",
    "# Employees": 16000,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 119,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 44,
    Content: 71,
    "Demand Generation": "",
    Events: "",
    SEO: 4,
    "nr. Channels": 2,
  },
  {
    "Account Name": "CROSSMARK",
    Website: "http://www.crossmark.com",
    "# Employees": 40000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 119,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 35,
    Content: "",
    "Demand Generation": "",
    Events: 84,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Twitter",
    Website: "http://www.twitter.com",
    "# Employees": 7100,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 113,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 49,
    Content: 56,
    "Demand Generation": "",
    Events: 6,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Instacart",
    Website: "http://www.instacart.com",
    "# Employees": 17000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 111,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 72,
    Content: 37,
    "Demand Generation": "",
    Events: 1,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "NBCUniversal",
    Website: "http://www.nbcuniversal.com",
    "# Employees": 55000,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 111,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 51,
    Content: 9,
    "Demand Generation": "",
    Events: 43,
    SEO: 8,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Wayfair",
    Website: "http://www.wayfaircareers.com",
    "# Employees": 15000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 110,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 37,
    Content: 56,
    "Demand Generation": "",
    Events: 7,
    SEO: 10,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Electronic Arts",
    Website: "http://www.ea.com",
    "# Employees": 30000,
    Industry: "computer games",
    "Company Country": "United States",
    "# People Results": 105,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 59,
    Content: 46,
    "Demand Generation": "",
    Events: "",
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Dow",
    Website: "http://www.dow.com",
    "# Employees": 43000,
    Industry: "chemicals",
    "Company Country": "United States",
    "# People Results": 103,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 10,
    Content: 58,
    "Demand Generation": 1,
    Events: 31,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Merkle",
    Website: "http://www.merkleinc.com",
    "# Employees": 6800,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 101,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 4,
    Content: 14,
    "Demand Generation": 3,
    Events: 3,
    SEO: 75,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Mattel, Inc.",
    Website: "http://www.mattel.com",
    "# Employees": 11000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 98,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 69,
    Content: 19,
    "Demand Generation": "",
    Events: 10,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "ESPN",
    Website: "http://www.espn.com",
    "# Employees": 12000,
    Industry: "media production",
    "Company Country": "United States",
    "# People Results": 96,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 7,
    Content: 65,
    "Demand Generation": 1,
    Events: 20,
    SEO: 3,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Saks Fifth Avenue",
    Website: "http://www.saksfifthavenue.com",
    "# Employees": 9600,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 95,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 61,
    Content: 10,
    "Demand Generation": "",
    Events: 23,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Kaiser Permanente",
    Website: "http://www.kaiserpermanente.org",
    "# Employees": 304000,
    Industry: "hospital & health care",
    "Company Country": "United States",
    "# People Results": 94,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 24,
    Content: 58,
    "Demand Generation": "",
    Events: 9,
    SEO: 3,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Coca-Cola Company",
    Website: "http://www.coca-colacompany.com",
    "# Employees": 79000,
    Industry: "food & beverages",
    "Company Country": "United States",
    "# People Results": 93,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 63,
    Content: 30,
    "Demand Generation": "",
    Events: "",
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Yahoo",
    Website: "http://www.yahooinc.com",
    "# Employees": 9400,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 93,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 1,
    Content: 73,
    "Demand Generation": "",
    Events: "",
    SEO: 19,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Kimberly-Clark",
    Website: "http://www.kimberly-clark.com",
    "# Employees": 34000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 93,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 64,
    Content: 22,
    "Demand Generation": 5,
    Events: "",
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Wiley",
    Website: "http://www.wiley.com",
    "# Employees": 9700,
    Industry: "publishing",
    "Company Country": "United States",
    "# People Results": 92,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 41,
    Content: 36,
    "Demand Generation": 3,
    Events: 7,
    SEO: 5,
    "nr. Channels": 2,
  },
  {
    "Account Name": "PlayStation",
    Website: "http://www.playstation.com",
    "# Employees": 11000,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 90,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 38,
    Content: 43,
    "Demand Generation": "",
    Events: 9,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Edelman",
    Website: "http://www.edelman.com",
    "# Employees": 7000,
    Industry: "public relations & communications",
    "Company Country": "United States",
    "# People Results": 89,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 59,
    Content: 20,
    "Demand Generation": 1,
    Events: 9,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "PepsiCo",
    Website: "http://www.pepsico.com",
    "# Employees": 131000,
    Industry: "food & beverages",
    "Company Country": "United States",
    "# People Results": 92,
    ABM: 4,
    "Account Based": "",
    "ABM Total": 4,
    Brand: 61,
    Content: 10,
    "Demand Generation": 1,
    Events: 12,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Boston Consulting Group (BCG)",
    Website: "http://www.bcg.com",
    "# Employees": 32000,
    Industry: "management consulting",
    "Company Country": "United States",
    "# People Results": 89,
    ABM: 3,
    "Account Based": "",
    "ABM Total": 3,
    Brand: 10,
    Content: 25,
    "Demand Generation": "",
    Events: 44,
    SEO: 4,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Young Living Essential Oils",
    Website: "http://www.youngliving.com",
    "# Employees": 14000,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 86,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 64,
    Content: 7,
    "Demand Generation": "",
    Events: 11,
    SEO: 4,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Colgate-Palmolive",
    Website: "http://www.colgatepalmolive.com",
    "# Employees": 34000,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 90,
    ABM: 5,
    "Account Based": "",
    "ABM Total": 5,
    Brand: 61,
    Content: 12,
    "Demand Generation": 1,
    Events: 2,
    SEO: 4,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Nintendo",
    Website: "http://www.nintendo.com",
    "# Employees": 4700,
    Industry: "computer games",
    "Company Country": "United States",
    "# People Results": 85,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 56,
    Content: 18,
    "Demand Generation": "",
    Events: 9,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Audacy, Inc.",
    Website: "http://www.audacyinc.com",
    "# Employees": 5100,
    Industry: "media production",
    "Company Country": "United States",
    "# People Results": 84,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 38,
    Content: 35,
    "Demand Generation": 1,
    Events: 10,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "BPO",
    Website: "http://www.bposolutionsamerica.com",
    "# Employees": 5400,
    Industry: "outsourcing/offshoring",
    "Company Country": "United States",
    "# People Results": 83,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 4,
    Content: 45,
    "Demand Generation": "",
    Events: 2,
    SEO: 32,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Macy's",
    Website: "http://www.macys.com",
    "# Employees": 7100,
    Industry: "apparel & fashion",
    "Company Country": "United States",
    "# People Results": 82,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 48,
    Content: 3,
    "Demand Generation": "",
    Events: 27,
    SEO: 4,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Roku Inc.",
    Website: "http://www.weareroku.com",
    "# Employees": 4000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 82,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 23,
    Content: 50,
    "Demand Generation": "",
    Events: 6,
    SEO: 3,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Gap",
    Website: "http://www.gapinc.com",
    "# Employees": 117000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 81,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 48,
    Content: 9,
    "Demand Generation": "",
    Events: 19,
    SEO: 5,
    "nr. Channels": 2,
  },
  {
    "Account Name": "QVC",
    Website: "http://www.qvc.com",
    "# Employees": 8800,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 81,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 34,
    Content: 37,
    "Demand Generation": "",
    Events: 8,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Encore",
    Website: "http://www.encoreglobal.com",
    "# Employees": 6700,
    Industry: "events services",
    "Company Country": "United States",
    "# People Results": 83,
    ABM: 2,
    "Account Based": "",
    "ABM Total": 2,
    Brand: 44,
    Content: 10,
    "Demand Generation": 1,
    Events: 23,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Uber",
    Website: "http://www.uber.com",
    "# Employees": 85000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 81,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 4,
    Content: 55,
    "Demand Generation": 1,
    Events: 6,
    SEO: 13,
    "nr. Channels": 2,
  },
  {
    "Account Name": "The Salvation Army",
    Website: "http://www.salvationarmyusa.org",
    "# Employees": 29000,
    Industry: "nonprofit organization management",
    "Company Country": "United States",
    "# People Results": 80,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 7,
    Content: 11,
    "Demand Generation": "",
    Events: 61,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "JLL",
    Website: "http://www.jll.com",
    "# Employees": 98000,
    Industry: "real estate",
    "Company Country": "United States",
    "# People Results": 80,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 9,
    Content: 17,
    "Demand Generation": 7,
    Events: 45,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Forever",
    Website: "http://www.forever.com",
    "# Employees": 2200,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 78,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 54,
    Content: 11,
    "Demand Generation": "",
    Events: 10,
    SEO: 3,
    "nr. Channels": 2,
  },
  {
    "Account Name": "CBRE",
    Website: "http://www.cbre.com",
    "# Employees": 105000,
    Industry: "real estate",
    "Company Country": "United States",
    "# People Results": 77,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 9,
    Content: 19,
    "Demand Generation": "",
    Events: 48,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "The Hershey Company",
    Website: "http://www.thehersheycompany.com",
    "# Employees": 7400,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 78,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 61,
    Content: 13,
    "Demand Generation": "",
    Events: 1,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Casa",
    Website: "http://www.getcasa.co",
    "# Employees": 9400,
    Industry: "internet",
    "Company Country": "United States",
    "# People Results": 77,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 22,
    Content: 8,
    "Demand Generation": "",
    Events: 39,
    SEO: 8,
    "nr. Channels": 2,
  },
  {
    "Account Name": "PBS",
    Website: "http://www.pbs.org",
    "# Employees": 2400,
    Industry: "media production",
    "Company Country": "United States",
    "# People Results": 77,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 7,
    Content: 50,
    "Demand Generation": "",
    Events: 16,
    SEO: 4,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Under Armour",
    Website: "http://www.underarmour.com",
    "# Employees": 17000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 77,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 43,
    Content: 20,
    "Demand Generation": "",
    Events: 9,
    SEO: 5,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Nestl√© Purina North America",
    Website: "http://www.purina.com",
    "# Employees": 7700,
    Industry: "mechanical or industrial engineering",
    "Company Country": "United States",
    "# People Results": 76,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 63,
    Content: 11,
    "Demand Generation": "",
    Events: 2,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "IMG",
    Website: "http://www.img.com",
    "# Employees": 4300,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 76,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 3,
    Content: 42,
    "Demand Generation": "",
    Events: 28,
    SEO: 3,
    "nr. Channels": 2,
  },
  {
    "Account Name": "PGA TOUR",
    Website: "http://www.pgatour.com",
    "# Employees": 2600,
    Industry: "sports",
    "Company Country": "United States",
    "# People Results": 75,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 9,
    Content: 54,
    "Demand Generation": "",
    Events: 12,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Mastercard",
    Website: "http://www.mastercard.us",
    "# Employees": 30000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 74,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 19,
    Content: 39,
    "Demand Generation": 5,
    Events: 9,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "CDW",
    Website: "http://www.cdw.com",
    "# Employees": 14000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 73,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 49,
    Content: 8,
    "Demand Generation": 1,
    Events: 14,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Pinterest",
    Website: "http://www.pinterest.com",
    "# Employees": 7100,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 73,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 31,
    Content: 41,
    "Demand Generation": "",
    Events: "",
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Workday",
    Website: "http://www.workday.com",
    "# Employees": 19000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 84,
    ABM: 9,
    "Account Based": 4,
    "ABM Total": 13,
    Brand: 8,
    Content: 45,
    "Demand Generation": 3,
    Events: 1,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "CAFE",
    Website: "http://www.cafe.com",
    "# Employees": 5100,
    Industry: "media production",
    "Company Country": "United States",
    "# People Results": 71,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: "",
    Content: 41,
    "Demand Generation": 1,
    Events: 29,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "GroupM",
    Website: "http://www.groupm.com",
    "# Employees": 27000,
    Industry: "marketing & advertising",
    "Company Country": "United States",
    "# People Results": 71,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 8,
    Content: 25,
    "Demand Generation": "",
    Events: 2,
    SEO: 36,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Neiman Marcus Group",
    Website: "http://www.neimanmarcusgroup.com",
    "# Employees": 10000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 71,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 53,
    Content: 15,
    "Demand Generation": "",
    Events: 1,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "The Walt Disney Company",
    Website: "http://www.thewaltdisneycompany.com",
    "# Employees": 169000,
    Industry: "entertainment",
    "Company Country": "United States",
    "# People Results": 70,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 11,
    Content: 6,
    "Demand Generation": "",
    Events: 47,
    SEO: 6,
    "nr. Channels": 2,
  },
  {
    "Account Name": "2U",
    Website: "http://www.2u.com",
    "# Employees": 4100,
    Industry: "government administration",
    "Company Country": "United States",
    "# People Results": 70,
    ABM: 1,
    "Account Based": "",
    "ABM Total": 1,
    Brand: 31,
    Content: 26,
    "Demand Generation": 2,
    Events: 3,
    SEO: 6,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Qualtrics",
    Website: "http://www.qualtrics.com",
    "# Employees": 6200,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 73,
    ABM: 4,
    "Account Based": "",
    "ABM Total": 4,
    Brand: 40,
    Content: 15,
    "Demand Generation": 1,
    Events: 7,
    SEO: 2,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Westfield",
    Website: "http://www.westfieldinsurance.com",
    "# Employees": 2100,
    Industry: "insurance",
    "Company Country": "United States",
    "# People Results": 69,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 48,
    Content: 9,
    "Demand Generation": "",
    Events: 12,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Lyft",
    Website: "http://www.lyft.com",
    "# Employees": 24000,
    Industry: "leisure, travel & tourism",
    "Company Country": "United States",
    "# People Results": 69,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 43,
    Content: 25,
    "Demand Generation": 1,
    Events: "",
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "Peloton Interactive",
    Website: "http://www.onepeloton.com",
    "# Employees": 8700,
    Industry: "health, wellness & fitness",
    "Company Country": "United States",
    "# People Results": 69,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 39,
    Content: 25,
    "Demand Generation": 1,
    Events: 3,
    SEO: 1,
    "nr. Channels": 2,
  },
  {
    "Account Name": "DICK'S Sporting Goods",
    Website: "http://www.dickssportinggoods.com",
    "# Employees": 24000,
    Industry: "retail",
    "Company Country": "United States",
    "# People Results": 69,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 20,
    Content: 46,
    "Demand Generation": "",
    Events: "",
    SEO: 3,
    "nr. Channels": 2,
  },
  {
    "Account Name": "Stanford University",
    Website: "http://www.stanford.edu",
    "# Employees": 25000,
    Industry: "higher education",
    "Company Country": "United States",
    "# People Results": 67,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 3,
    Content: 13,
    "Demand Generation": "",
    Events: 51,
    SEO: "",
    "nr. Channels": 2,
  },
  {
    "Account Name": "PTC",
    Website: "http://www.ptc.com",
    "# Employees": 7100,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 66,
    ABM: "",
    "Account Based": "",
    "ABM Total": 0,
    Brand: 14,
    Content: 31,
    "Demand Generation": 10,
    Events: 4,
    SEO: 7,
    "nr. Channels": 2,
  },
  {
    "Account Name": "SAS",
    Website: "http://www.sas.com",
    "# Employees": 16000,
    Industry: "information technology & services",
    "Company Country": "United States",
    "# People Results": 70,
    ABM: 4,
    "Account Based": "",
    "ABM Total": 4,
    Brand: 24,
    Content: 9,
    "Demand Generation": "",
    Events: 25,
    SEO: 4,
    "nr. Channels": 2,
  },
]

export default DataUSMarketing
